import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const NotFound = () => {
  return (
    <Container>
      <Row>
        <Col className="vh-100 d-flex flex-column align-items-center justify-content-center">
          <h1 className="display-1">404</h1>
          <div>Not found</div>
        </Col>
      </Row>
    </Container>
  )
}

export default NotFound;